





















import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    participantId: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props, { root }) {
    const state = reactive({
      items: [],
      loaded: false,
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`presence/list/${props.participantId}`)
        .then(({ data }) => {
          state.items = data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
        });
    };

    onMounted(fetchData);
    return { state };
  },
});
